<template>
    <div>
        <WhyBajaCalifornia />
        <footer-component />
    </div>
</template>
<script>
import WhyBajaCalifornia from "../components/about-us/WhyBajaCalifornia";
import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
import { changeInformationSEO } from "@/providers/KeywordsServices";

export default {
    name: "WhyBajaCaliforniaView",
    title: "Why Baja California | Baja California Health Tourism",
    components: { WhyBajaCalifornia, FooterComponent },
    mounted() {
        changeInformationSEO();
    }
};
</script>
